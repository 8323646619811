Vue.component('contract-variables', {
    props: {
        translations: Object,
        updateUrl: String,
        previewUrl: String,
        initialData: Object,
        customerData: Object,
        countries: Array,
        numberOfContainers: String|Number,
        maintenanceUnits: String|Number,
        pricePerUnit: String|Number,
        showFullVariables: Boolean,
    },
    data: function () {
        return {
            form_fields: {
                type_sla: {
                    default: 'inside',
                    options: [
                        'inside',
                        'greenwall',
                        'outside',
                        'artificial',
                        'christmas',
                    ]
                },
                based_on: {
                    default: 'allin',
                    options: [
                        'allin',
                        'lease',
                        'financial-lease',
                    ]
                },
                sla_options: {
                    default: true
                },
                sla_options_text: {
                    default: ''
                },
                number_of_containers: {
                    placeholder: this.$props.numberOfContainers,
                },
                maintenance_units: {
                    placeholder: this.$props.maintenanceUnits,
                },
                price_per_unit: {
                    placeholder: this.$props.pricePerUnit,
                },
                visits: {
                    default: 10,
                    min: 1,
                    max: 12
                },
                extra_visits: {
                    default: 0
                },
                payment_term: {
                    default: 14,
                    options: [
                        14,
                        30,
                        60,
                        90,
                    ]
                },
                contract_duration: {
                    default: 1,
                },
                notice_period: {
                    default: 6
                },
                contract_number: {
                    default: ''
                },
                needs_signing: {
                    default: false
                },
                needs_signing_name: {
                    default: ''
                },
                additionals: {
                    default: [
                        {
                            enabled: true,
                            label: 'Parkeerkosten',
                            amount: null,
                            description: null
                        },
                        {
                            enabled: true,
                            label: 'Huur hoogwerker',
                            amount: null,
                            description: null
                        }
                    ],
                    template: {
                        enabled: true,
                        label: null,
                        amount: null,
                        description: null
                    }
                },
                overwrite_contact: {
                    default: false
                },
                contact: {
                    default: {
                        company: '',
                        name: '',
                        address: '',
                        address2: '',
                        address3: '',
                        zipcode: '',
                        city: '',
                        country: this.customerData.countryID,
                        code: '',
                    },
                    defaultOnEmpty: true,
                    company: {
                        placeholder: this.customerData.title,
                    },
                    name: {
                        placeholder: ''
                    },
                    address: {
                        placeholder: this.customerData.address
                    },
                    address2: {
                        placeholder: this.customerData.address2
                    },
                    address3: {
                        placeholder: this.customerData.address3
                    },
                    zipcode: {
                        placeholder: this.customerData.zipcode
                    },
                    city: {
                        placeholder: this.customerData.city
                    },
                    country: {
                        placeholder: this.getCountry(this.customerData.countryID)
                    },
                    code: {
                        placeholder: this.customerData.code.trim(),
                    }
                }
            },
            form_data: {
                overwrite_contact: this.initialData.overwrite_contact,
                contact: {
                    company: '',
                    name: '',
                    address: '',
                    address2: '',
                    address3: '',
                    zipcode: '',
                    city: '',
                    country: this.customerData.countryID
                }
            },
            debounce_timer: null,
            debounce_interval: 500,
            currentContractDurationByLease: 1,
            newContractDurationByLease: null
        }
    },
    computed: {
    },
    watch: {
        form_data: {
            deep: true,
            handler() {
                clearTimeout(this.debounce_timer);

                this.debounce_timer = setTimeout(this.update, this.debounce_interval);
            }
        },
        newContractDurationByLease: function(val){
            if(val !== this.currentContractDurationByLease) {
                this.form_data.contract_duration = val;
                this.currentContractDurationByLease = val;
            }
        }
    },
    methods: {
        getCountry(countryID) {
            const country = this.countries.find((c) => c.countryID === countryID);
            if (country) {
                return country.title;
            }

            return '';
        },
        trans(key) {
            let value = this.translations[key];
            if (value === null || value === undefined) {
                value = key;
            }
            return value.replace(/\\n/g, "\n");
        },
        update() {
            this.$emit('is-loading', true);
            API.PUT(this.updateUrl, this.form_data);
        },
        deleteAdditional(index) {
            Vue.set(
                this.form_data,
                'additionals',
                this.form_data.additionals.filter((a, i) => i !== index));

        },
        addAdditional() {
            Vue.set(
                this.form_data,
                'additionals',
                [...this.form_data.additionals, JSON.parse(JSON.stringify(this.form_fields.additionals.template))]);
        },
        isVisibleAgreement(key) {
            this.newContractDurationByLease = this.$parent.currentContractDurationByLease;

            if (this.$parent.agreements.filter((agreement) => {
                return agreement.id === key && agreement.hidden === true;
            }).length === 1) {
                return false;
            }

            return true;
        }
    },
    created() {
        for(var name in this.form_fields) {
            let defaultValue = (this.form_fields[name].hasOwnProperty('default')
                ? this.form_fields[name].default
                : null);

            const defaultOnEmpty = this.form_fields[name].hasOwnProperty('defaultOnEmpty')
                && this.form_fields[name].defaultOnEmpty === true;

            const existsInInitialData = this.initialData.hasOwnProperty(name)

            const valueInInitialData = this.initialData[name];

            const emptyInInitialData = valueInInitialData === null || valueInInitialData === '';

            Vue.set(
                this.form_data,
                name,
                (existsInInitialData && (!defaultOnEmpty || (defaultOnEmpty && !emptyInInitialData))
                    ? valueInInitialData
                    : defaultValue)
            );
        }
    },
    mounted() {
    },
    destroyed() {
    }
});
